import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Github from "./Github";
import Techstack from "./Techstack";
import Toolstack from "./Toolstack";
import { BsFillBrightnessAltHighFill, BsFillFileEarmarkBarGraphFill, BsFillEmojiWinkFill } from "react-icons/bs";

function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={12}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "20px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              SELF-<strong className="purple">PORTRAIT</strong>
            </h1>
          </Col>

        </Row>
        <Row style={{ justifyContent: "center", padding: "10px", paddingBottom: "50px" }}>
          <Col md={4} style={{ justifyContent: "center" }}>
            <div style={{
              padding: "50px 5px",
              height: "100%",
              weight: "100%",
              background: "rgba(0, 0, 0, 0.5)",
            }}>
              <BsFillBrightnessAltHighFill fontSize={40} />
              <br />
              <br />
              <p>
                Self-motivated early career software developer, with experience building web applications and form data structure and algorithm foundation.
              </p>
            </div>
          </Col>
          <Col md={4} style={{ justifyContent: "center" }}>
            <div style={{
              padding: "50px 5px",
              height: "100%",
              weight: "100%",
              background: "rgba(0, 0, 0, 0.5)"
            }}>
              <BsFillFileEarmarkBarGraphFill fontSize={40} />
              <br />
              <br />
              <p>Data science analytics manager, leading development of data solution projects supporting clients from various industries, as well as internal projects including proposals and platform migration</p>
            </div>
          </Col>
          <Col md={4} style={{ justifyContent: "center" }}>
            <div style={{
              padding: "50px 5px",
              height: "100%",
              weight: "100%",
              background: "rgba(0, 0, 0, 0.5)"
            }}>
              <BsFillEmojiWinkFill fontSize={40} />
              <br />
              <br />
              <p>Travel and food lover, exploring the fantastic world eagerly and spending very minute to feel it with a curious and unrestrained heart. </p>
            </div>
          </Col>

        </Row>
        <h1 className="project-heading" >
          PROFESSIONAL <strong className="purple">SKILLS </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          DEVELOPER<strong className="purple"> TOOLS</strong>
        </h1>
        <Toolstack />

        {/* <Github /> */}
      </Container>
    </Container>
  );
}

export default About;
