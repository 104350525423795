import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import seckill from "../../Assets/Projects/seckill.png";
import crypto from "../../Assets/Projects/crypto.png";
import map from "../../Assets/Projects/map.png";
import camp from "../../Assets/Projects/yelpcamp.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Projects </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few applications I've recently completed.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

          <Col lg={6} className="project-card">
            <ProjectCard
              imgPath={camp}
              title="YelpCamp"
              description="Web application provides a community for camping lovers to recommend campgrounds to others and leave reviews like Yelp."
              link="https://morning-shore-90663.herokuapp.com/"
            />
          </Col>

          <Col lg={6} className="project-card">
            <ProjectCard
              imgPath={seckill}
              title="Seckill"
              description="E-commerce web application implementing functionalities such as customer OTP register and login, product purchase and limited-time offers for selected items."
              link="https://github.com/hellrambler/Seckill_Ecommerce_Project"
            />
          </Col>

          <Col lg={6} className="project-card">
            <ProjectCard
              imgPath={crypto}
              title="Kryptorambler"
              description="Single-page web applications supporting Ethereum transactions, enabling crypto wallet connection, Ethereum transfer and visualization with customized GIF of historical transactions."
              link="https://kryptorambler.com/"
            />
          </Col>





          <Col lg={6} className="project-card">
            <ProjectCard
              imgPath={map}
              isBlog={false}
              title="BearMap"
              description="Map application supporting map rastering, route recommendation to users, location search as well as type auto-completion to improve the user experience."
              link="http://bearmaps-rambler.herokuapp.com/map.html"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
