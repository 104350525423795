import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/self.png";
import Tilt from "react-parallax-tilt";
function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              ABOUT <span className="purple"> MYSELF </span>
            </h1>
            <p className="home-about-body">
              I found my interest in building and developing program more than data analyzing last year while working on a web scraping project
              <span role="img" aria-label="spider"> 🕸️ </span> while sourcing public information for competitive analysis <span role="img" aria-label="da"> 📊 </span>
              <br />
              <br />Before that, I was fluent already in
              <i>
                <b className="purple"> Python, data science packages and analytical tools. </b>
              </i>
              <br />
              <br />
              Later, I started learning coding languages, including
              <i>
                <b className="purple"> Java, Javascript, HTML and CSS
                </b>
              </i>
              , and
              <i>
                <b className="purple"> data structures and algorithms
                </b>
              </i> to enhance my developing skills.
              <br />
              <br />
              I am also looking for hands-on web application projects using frameworks like
              <i>
                <b className="purple"> Spring Boot, MyBatis, React, Express, and Bootstrap
                </b>
              </i>
              <br />
              <br />
              I am still expanding my capabilities as a web developer and I will continuously do so whenever possible.

            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid self" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
