import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { BiLinkExternal } from "react-icons/bi";

const ProjectCards = (props) => (
  <Card className="project-card-view">
    <Card.Img variant="top" src={props.imgPath} alt="card-img" />
    <Card.Body>
      <Card.Title style={{ fontWeight: "bold" }}>{props.title}</Card.Title>
      <Card.Text style={{ textAlign: "left" }}>
        {props.description}
      </Card.Text>
      <Button variant="primary" href={props.link} target="_blank"
        rel="noopener noreferrer">
        <BiLinkExternal /> &nbsp;
        View Project
      </Button>
    </Card.Body>
  </Card>
);

export default ProjectCards;
